import './App.css';
import FlippableCard from './components/flippable-card';


function App() {
  return (
    <div className="App">
      <FlippableCard/>
    </div>
  );
}

export default App;
